import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import createAxiosInstance2 from '../../utils/AxiosConfig2';
import './GuiaUnal.css'; // Asegúrate de crear este archivo CSS si es necesario
import { useNavigate } from 'react-router-dom';

export const GuiaUnal = () => {
  const navigate = useNavigate();
  const [globalRandomNumber, setGlobalRandomNumber] = useState(0);
  const [globalRandomNumber2, setGlobalRandomNumber2] = useState(0);
  const [form1, setForm1] = useState({ name: '', id: 0 });
  const [form2, setForm2] = useState({
    title: '',
    generalDescription: '',
    contenidosJson: [],
    linkInterest: '',
    tituloLink: '',
    id: 0,
    menuId: 0,
  });
  const [activeForm, setActiveForm] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    const number1 = generateRandomNumber(10000, 99999);
    const number2 = generateRandomNumber(10000, 99999);

    setGlobalRandomNumber(number1);
    setGlobalRandomNumber2(number2);

    setForm1({ name: '', id: number1 });
    setForm2({
      title: '',
      generalDescription: '',
      contenidosJson: [],
      linkInterest: '',
      tituloLink: '',  
      id: number2,
      menuId: number1,
    });

    console.log('Generated random number 1:', number1);
    console.log('Generated random number 2:', number2);
  }, []);

  const handleAddContent = () => {
    setForm2((prevForm2) => ({
      ...prevForm2,
      contenidosJson: [
        ...prevForm2.contenidosJson,
        { titulo: '', contenido: '', imageUrl: '', tituloLink: '', link: '' },
      ],
    }));
  };

  const handleRemoveContent = (index) => {
    setForm2((prevForm2) => {
      const newContenidosJson = [...prevForm2.contenidosJson];
      newContenidosJson.splice(index, 1);
      return {
        ...prevForm2,
        contenidosJson: newContenidosJson,
      };
    });
  };

  const handleChangeContent = (index, field, value) => {
    if (value.length > 2000) {
      setErrorMessage('Has excedido el número máximo de caracteres (2000).');
      return;
    }
    setErrorMessage('');
    setForm2((prevForm2) => {
      const newContenidosJson = [...prevForm2.contenidosJson];
      newContenidosJson[index][field] = value;
      return {
        ...prevForm2,
        contenidosJson: newContenidosJson,
      };
    });
  };

  const handleInputChange = (setter, field, value) => {
    if (value.length > 2000) {
      setErrorMessage('Has excedido el número máximo de caracteres (2000).');
      return;
    }
    setErrorMessage('');
    setter((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (event, formNumber) => {
    event.preventDefault();

    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    let formData;
    let url;

    switch (formNumber) {
      case 1:
        formData = form1;
        url = '/menu-request';
        break;
      case 2:
        formData = form2;
        url = '/contenidos-request';
        break;
      default:
        return;
    }

    try {
      const response = await createAxiosInstance2().post(url, formData);
      console.log(response);

      if (formNumber === 1) {
        setForm1({ ...form1, message: '¡Formulario 1 enviado con éxito!' });
        setActiveForm(2);
      } else if (formNumber === 2) {
        setForm2({ ...form2, message: '¡Formulario 2 enviado con éxito!' });
      }
    } catch (error) {
      console.error(error);
      if (formNumber === 1) {
        setForm1({ ...form1, message: 'Error al enviar el formulario 1.' });
      } else if (formNumber === 2) {
        setForm2({ ...form2, message: 'Error al enviar el formulario 2.' });
      }
    }
  };

  const handleImageChange = (index, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setForm2((prevForm2) => {
        const newContenidosJson = [...prevForm2.contenidosJson];
        newContenidosJson[index].imageUrl = reader.result;
        return {
          ...prevForm2,
          contenidosJson: newContenidosJson,
        };
      });
    };
  };

  const renderForm = (formData, setFormData, formNumber) => (
    <Box component="form" onSubmit={(e) => handleSubmit(e, formNumber)} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {formNumber === 1 && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título de la sección"
              value={formData.name}
              onChange={(e) => handleInputChange(setFormData, 'name', e.target.value)}
              inputProps={{ maxLength: 2000 }}
            />
          </Grid>
        )}
        {formNumber === 2 && (
          <>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Título"
                value={formData.title}
                onChange={(e) => handleInputChange(setFormData, 'title', e.target.value)}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Descripción General"
                value={formData.generalDescription}
                onChange={(e) => handleInputChange(setFormData, 'generalDescription', e.target.value)}
                multiline
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Título del Enlace"
                value={formData.tituloLink}
                onChange={(e) => handleInputChange(setFormData, 'tituloLink', e.target.value)}
                multiline
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Enlace"
                value={formData.linkInterest}
                onChange={(e) => handleInputChange(setFormData, 'linkInterest', e.target.value)}
                multiline
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Entradas de Contenido</Typography>
            </Grid>
            {formData.contenidosJson.map((content, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Título entrada ${index + 1}`}
                    value={content.titulo}
                    onChange={(e) => handleChangeContent(index, 'titulo', e.target.value)}
                    inputProps={{ maxLength: 2000 }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Contenido entrada ${index + 1}`}
                    value={content.contenido}
                    onChange={(e) => handleChangeContent(index, 'contenido', e.target.value)}
                    multiline
                    inputProps={{ maxLength: 2000 }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Título del Enlace ${index + 1}`}
                    value={content.tituloLink}
                    onChange={(e) => handleChangeContent(index, 'tituloLink', e.target.value)}
                    inputProps={{ maxLength: 2000 }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Enlace ${index + 1}`}
                    value={content.link}
                    onChange={(e) => handleChangeContent(index, 'link', e.target.value)}
                    inputProps={{ maxLength: 2000 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ mt: 2 }}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`contained-button-file-${index}`}
                    multiple
                    type="file"
                    onChange={(e) => handleImageChange(index, e.target.files[0])}
                  />
                  <label htmlFor={`contained-button-file-${index}`}>
                    <Button variant="contained" color="primary" component="span">
                      Subir Imagen
                    </Button>
                  </label>
                </Grid>
                {content.imageUrl && (
                  <Grid item xs={12} md={8} sx={{ mt: 2 }}>
                    <img src={content.imageUrl} alt={`Imagen ${index + 1}`} style={{ maxWidth: '100%' }} />
                  </Grid>
                )}
                <Grid item xs={12} md={2} sx={{ mt: 2 }}>
                  <IconButton onClick={() => handleRemoveContent(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleAddContent}>
                Agregar entrada de contenido
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Crear
      </Button>
      {formData.message && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {formData.message}
        </Typography>
      )}
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gestionar entradas de la sección
      </Typography>
      <Accordion expanded={activeForm === 1} onChange={() => setActiveForm(1)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Título de la sección en el menú</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderForm(form1, setForm1, 1)}</AccordionDetails>
      </Accordion>
      <Accordion expanded={activeForm === 2} onChange={() => setActiveForm(2)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Administrar entradas</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderForm(form2, setForm2, 2)}</AccordionDetails>
      </Accordion>

      <Button variant="contained" color="secondary" onClick={() => navigate('/ListaUnal')} sx={{ mt: 2 }}>
        Terminar
      </Button>
    </Box>
  );
};

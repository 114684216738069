/* import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import createAxiosInstance2 from '../../utils/AxiosConfig2';
import { useParams, useNavigate } from 'react-router-dom';

export const OportunidadesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form1, setForm1] = useState({ name: '', id: 0 });
  const [form2, setForm2] = useState({
    title: '',
    generalDescription: '',
    contenidosJson: [],
    linkInterest: '',
    id: 0,
  });

  useEffect(() => {
    // Fetch the data from the backend
    createAxiosInstance2().get(`/oportunidades-menu/${id}`)
      .then(response => {
        const fetchedData = response.data;
        const oportunidadesContent = fetchedData.oportunidadesContent && fetchedData.oportunidadesContent[0] ? fetchedData.oportunidadesContent[0] : {};
        setForm1({
          name: fetchedData.name || '',
          id: fetchedData.id,
        });
        setForm2({
          title: oportunidadesContent.title || '',
          generalDescription: oportunidadesContent.generalDescription || '',
          contenidosJson: oportunidadesContent.contenidosJson || [],
          linkInterest: oportunidadesContent.linkInterest || '',
          id: oportunidadesContent.id || 0,
        });
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id]);

  const handleAddContent = () => {
    setForm2((prevForm2) => ({
      ...prevForm2,
      contenidosJson: [...prevForm2.contenidosJson, { titulo: '', contenido: '' }],
    }));
  };

  const handleRemoveContent = (index) => {
    setForm2((prevForm2) => {
      const newContenidosJson = [...prevForm2.contenidosJson];
      newContenidosJson.splice(index, 1);
      return {
        ...prevForm2,
        contenidosJson: newContenidosJson,
      };
    });
  };

  const handleChangeContent = (index, field, value) => {
    setForm2((prevForm2) => {
      const newContenidosJson = [...prevForm2.contenidosJson];
      newContenidosJson[index][field] = value;
      return {
        ...prevForm2,
        contenidosJson: newContenidosJson,
      };
    });
  };

  const handleSubmit = async (event, formNumber) => {
    event.preventDefault();
    let formData;
    let url;

    switch (formNumber) {
      case 1:
        formData = form1;
        url = `/oportunidades-menu/${id}`;
        break;
      case 2:
        formData = form2;
        url = `/oportunidades-contents/${form2.id}`;
        break;
      default:
        return;
    }

    try {
      const response = await createAxiosInstance2().patch(url, formData);
      console.log(response);

      if (formNumber === 1) {
        setForm1({ ...form1, message: '¡Formulario 1 actualizado con éxito!' });
      } else if (formNumber === 2) {
        setForm2({ ...form2, message: '¡Formulario 2 actualizado con éxito!' });
      }
    } catch (error) {
      console.error(error);
      if (formNumber === 1) {
        setForm1({ ...form1, message: 'Error al actualizar el formulario 1.' });
      } else if (formNumber === 2) {
        setForm2({ ...form2, message: 'Error al actualizar el formulario 2.' });
      }
    }
  };

  const renderForm = (formData, setFormData, formNumber) => (
    <Box component="form" onSubmit={(e) => handleSubmit(e, formNumber)} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {formNumber === 1 && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título de la sección"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Grid>
        )}
        
        {formNumber === 2 && (
          <>
          
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Título"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Descripción General"
                value={formData.generalDescription}
                onChange={(e) => setFormData({ ...formData, generalDescription: e.target.value })}
                multiline
              />
            </Grid>
            <Grid item xs={12} >
              <Typography variant="h6">Entradas de Contenido</Typography>
            </Grid>
            {formData.contenidosJson.map((content, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    label={`Título entrada ${index + 1}`}
                    value={content.titulo}
                    onChange={(e) => handleChangeContent(index, 'titulo', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    label={`Contenido entrada ${index + 1}`}
                    value={content.contenido}
                    onChange={(e) => handleChangeContent(index, 'contenido', e.target.value)}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <IconButton onClick={() => handleRemoveContent(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleAddContent}>
                Agregar entrada de contenido
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Actualizar
      </Button>
      <br />
      <br />
      <br />
      <br />
      <br />
      {formData.message && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {formData.message}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Editar entradas de la sección
      </Typography>
      {renderForm(form1, setForm1, 1)}
      {renderForm(form2, setForm2, 2)}
      <Button variant="contained" color="secondary" onClick={() => navigate('/oportunidadesLista')} sx={{ mt: 2 }}>
        Terminar
      </Button>
    </Box>
  );
};

export default OportunidadesEdit;


----------------------------------------------------------------------- */






import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import createAxiosInstance2 from '../../utils/AxiosConfig2';
import { useParams, useNavigate } from 'react-router-dom';

const handleError = (error) => {
  if (error.message === 'ResizeObserver loop completed with undelivered notifications.') {
    error.stopImmediatePropagation();
  }
};

window.addEventListener('error', handleError);

export const OportunidadesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form1, setForm1] = useState({ name: '', id: 0 });
  const [form2, setForm2] = useState({
    title: '',
    generalDescription: '',
    contenidosJson: [],
    linkInterest: '',
    tituloLink: '',
    id: 0,
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    createAxiosInstance2().get(`/oportunidades-menu/${id}`)
      .then(response => {
        const fetchedData = response.data;
        const oportunidadesContent = fetchedData.oportunidadesContent && fetchedData.oportunidadesContent[0] ? fetchedData.oportunidadesContent[0] : {};
        setForm1({
          name: fetchedData.name || '',
          id: fetchedData.id,
        });
        setForm2({
          title: oportunidadesContent.title || '',
          generalDescription: oportunidadesContent.generalDescription || '',
          contenidosJson: oportunidadesContent.contenidosJson ? oportunidadesContent.contenidosJson.map(content => ({
            ...content,
            imageUrl: content.imageUrl ? `https://sgcertificados.com/api/v1${content.imageUrl}` : '',
          })) : [],
          linkInterest: oportunidadesContent.linkInterest || '',
          tituloLink: oportunidadesContent.tituloLink || '',
          id: oportunidadesContent.id || 0,
        });
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id]);

  const handleAddContent = () => {
    setForm2((prevForm2) => ({
      ...prevForm2,
      contenidosJson: [
        ...prevForm2.contenidosJson,
        { titulo: '', contenido: '', imageUrl: '', tituloLink: '', link: '' },
      ],
    }));
  };

  const handleRemoveContent = (index) => {
    setForm2((prevForm2) => {
      const newContenidosJson = [...prevForm2.contenidosJson];
      newContenidosJson.splice(index, 1);
      return {
        ...prevForm2,
        contenidosJson: newContenidosJson,
      };
    });
  };

  const handleChangeContent = (index, field, value) => {
    setForm2((prevForm2) => {
      const newContenidosJson = [...prevForm2.contenidosJson];
      newContenidosJson[index][field] = value;
      return {
        ...prevForm2,
        contenidosJson: newContenidosJson,
      };
    });
  };

  const handleImageChange = (index, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setForm2((prevForm2) => {
        const newContenidosJson = [...prevForm2.contenidosJson];
        newContenidosJson[index].imageUrl = reader.result;
        return {
          ...prevForm2,
          contenidosJson: newContenidosJson,
        };
      });
    };
  };

  const handleSubmit = async (event, formNumber) => {
    event.preventDefault();
    let formData;
    let url;

    switch (formNumber) {
      case 1:
        formData = form1;
        url = `/oportunidades-menu/${id}`;
        break;
      case 2:
        // Remove the base URL part for image URLs
        formData = {
          ...form2,
          contenidosJson: form2.contenidosJson.map(content => ({
            ...content,
            imageUrl: content.imageUrl.startsWith('https://sgcertificados.com/api/v1') ?
              content.imageUrl.replace('https://sgcertificados.com/api/v1', '') :
              content.imageUrl,
          })),
        };
        url = `/oportunidades-contents/${form2.id}`;
        break;
      default:
        return;
    }

    try {
      const response = await createAxiosInstance2().patch(url, formData);
      console.log(response);

      setMessage(`¡Formulario ${formNumber} actualizado con éxito!`);
    } catch (error) {
      console.error(error);
      setMessage(`Error al actualizar el formulario ${formNumber}.`);
    }
  };

  const renderForm = (formData, setFormData, formNumber) => (
    <Box component="form" onSubmit={(e) => handleSubmit(e, formNumber)} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {formNumber === 1 && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título de la sección"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Grid>
        )}
        {formNumber === 2 && (
          <>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Título"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Descripción General"
                value={formData.generalDescription}
                onChange={(e) => setFormData({ ...formData, generalDescription: e.target.value })}
                multiline
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Título del Enlace"
                value={formData.tituloLink}
                onChange={(e) => setFormData({ ...formData, tituloLink: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Enlace"
                value={formData.linkInterest}
                onChange={(e) => setFormData({ ...formData, linkInterest: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Entradas de Contenido</Typography>
            </Grid>
            {formData.contenidosJson.map((content, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Título entrada ${index + 1}`}
                    value={content.titulo}
                    onChange={(e) => handleChangeContent(index, 'titulo', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Contenido entrada ${index + 1}`}
                    value={content.contenido}
                    onChange={(e) => handleChangeContent(index, 'contenido', e.target.value)}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Título del Enlace ${index + 1}`}
                    value={content.tituloLink}
                    onChange={(e) => handleChangeContent(index, 'tituloLink', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={`Enlace ${index + 1}`}
                    value={content.link}
                    onChange={(e) => handleChangeContent(index, 'link', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    component="label"
                  >
                    Subir Imagen
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleImageChange(index, e.target.files[0])}
                    />
                  </Button>
                  {content.imageUrl && (
                    <Box component="img" src={content.imageUrl} alt={`Imagen ${index + 1}`} sx={{ width: '100%', mt: 1 }} />
                  )}
                </Grid>

                <Grid item xs={12} md={8}>
                  <IconButton onClick={() => handleRemoveContent(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleAddContent}>
                Agregar entrada de contenido
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Actualizar
      </Button>
      {message && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {message}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Editar entradas de la sección
      </Typography>
      {renderForm(form1, setForm1, 1)}
      {renderForm(form2, setForm2, 2)}
      <Button variant="contained" color="secondary" onClick={() => navigate('/oportunidadesLista')} sx={{ mt: 2 }}>
        Terminar
      </Button>
    </Box>
  );
};

export default OportunidadesEdit;


import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import createAxiosInstance2 from '../../utils/AxiosConfig2';
import { useParams, useNavigate } from 'react-router-dom';

export const OportunidadesView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form1, setForm1] = useState({ name: '', id: 0 });
  const [form2, setForm2] = useState({
    title: '',
    generalDescription: '',
    contenidosJson: [],
    linkInterest: '',
    id: 0,
  });

  useEffect(() => {
    // Fetch the data from the backend
    createAxiosInstance2().get(`/oportunidades-menu/${id}`)
      .then(response => {
        const fetchedData = response.data;
        const oportunidadesContent = fetchedData.oportunidadesContent && fetchedData.oportunidadesContent[0] ? fetchedData.oportunidadesContent[0] : {};
        setForm1({
          name: fetchedData.name || '',
          id: fetchedData.id,
        });
        setForm2({
          title: oportunidadesContent.title || '',
          generalDescription: oportunidadesContent.generalDescription || '',
          contenidosJson: oportunidadesContent.contenidosJson || [],
          linkInterest: oportunidadesContent.linkInterest || '',
          id: oportunidadesContent.id || 0,
        });
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id]);

  const renderForm = (formData, formNumber) => (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {formNumber === 1 && (
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">Título de la sección</Typography>
            <Typography variant="body1">{formData.name}</Typography>
          </Grid>
        )}
        {formNumber === 2 && (
          <>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">Título</Typography>
              <Typography variant="body1">{formData.title}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" fontWeight="bold">Descripción General</Typography>
              <Typography variant="body1">{formData.generalDescription}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold">Entradas de Contenido</Typography>
            </Grid>
            {formData.contenidosJson.map((content, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1" fontWeight="bold">Título entrada {index + 1}</Typography>
                  <Typography variant="body1">{content.titulo}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1" fontWeight="bold">Contenido entrada {index + 1}</Typography>
                  <Typography variant="body1">{content.contenido}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Ver entradas de la sección
      </Typography>
      <Box sx={{ bgcolor: '#f0f0f0', p: 2, borderRadius: 1, mt: 2, width: 500 }}>
        <Typography variant="h6" gutterBottom>
          Nota: En esta sección se muestra la información de las subsecciones pero en la app se mostrarán de una manera diferente.
        </Typography>
      </Box>
      
      {renderForm(form1, 1)}
      {renderForm(form2, 2)}
      <Button variant="contained" color="secondary" onClick={() => navigate('/oportunidadesLista')} sx={{ mt: 2 }}>
        Terminar
      </Button>
    </Box>
  );
};

export default OportunidadesView;

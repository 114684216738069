import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../components/auth/Login';
import { PublicRoute } from './PublicRoute';
import { ProtectedRoute, ProtectedRouteByRole } from './ProtectedRoute';
import { Dashboard } from '../components/dashboard/Dashboard';
import Shell from '../components/layout/Shell';
import { Reports } from '../components/reports/reports';
import { SearchCertificates } from '../components/certificates/SearchCertificates';
import { ViewCertificates } from '../components/certificates/view-certificates/ViewCertificates';
import { RequestData } from '../components/certificates/request-data/RequestData';
import { PublicLayout } from '../components/layout/publicLayout';
import { VerificationCode } from '../components/certificates/verification-code/VerificationCode';
import { UserManage } from '../components/user-manage/UserManage';
import { Profile } from '../components/profile/Profile';
import Roles from '../enums/Roles.enum';
import { ResetPassword } from '../components/auth/ResetPassword';
import { GuiaUnal } from '../components/guia-unal/GuiaUnal';
import { ListaGuia } from '../components/guia-unal-lista/ListaGuia';
import { GuiaUnalEdit } from '../components/guia-unal-edit/GuiaUnalEdit';
import GuiaUnalView from '../components/guia-unal/GuiaUnalView';
import { OportunidadesForm } from '../components/oportunidades/oportunidadesForm';
import { OportunidadesLista } from '../components/oportunidades/OportunidadesLista';
import OportunidadesEdit from '../components/oportunidades/OportunidadesEdit';
import OportunidadesView from '../components/oportunidades/OportunidadesView';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="buscador-certificados" element={<PublicLayout content={ <SearchCertificates /> }/>} />
      <Route path="ver-certificados" element={<PublicLayout content={ <ViewCertificates /> }/>} />
      <Route path="solicitar-datos" element={<PublicLayout content={ <RequestData /> }/>} />
      <Route path="verificar-codigo" element={<PublicLayout content={ <VerificationCode /> }/>} />
      <Route element={<PublicRoute />}>   
        <Route path="login" element={<PublicLayout content={ <Login /> }/>} />
        <Route path="reset-password" element={<PublicLayout content={ <ResetPassword /> }/>} />
      </Route>
      <Route element={<ProtectedRoute />}>
      <Route path="guia" element={<PublicLayout content={ <GuiaUnal/> }/>} />
        <Route path="ListaUnal" element={<PublicLayout content={ <ListaGuia /> }/>} />
        <Route path="GuiaEdit/:id" element={<PublicLayout content={<GuiaUnalEdit />} />} />
        <Route path="Guiaview/:id" element={<PublicLayout content={<GuiaUnalView />} />} />

        <Route path="oportunidadesForm" element={<PublicLayout content={<OportunidadesForm />} />} />
        <Route path="oportunidadesLista" element={<PublicLayout content={ <OportunidadesLista /> }/>} />
        <Route path="oportunidadesEdit/:id" element={<PublicLayout content={<OportunidadesEdit />} />} />
        <Route path="OportunidadesView/:id" element={<PublicLayout content={<OportunidadesView />} />} />
        <Route path="reportes" element={<Shell content={<Reports />} />} />
        <Route element={ <ProtectedRouteByRole rolesNotAllowed={ [Roles.FacultySecretary ] } /> } >
          <Route path="dashboard" element={<Shell content={<Dashboard />} />} />
          <Route path="usuarios" element={<Shell content={<UserManage />} />} />
        </Route>
        <Route element={ <ProtectedRouteByRole rolesNotAllowed={ [Roles.Admin ] } /> } >
          <Route path="perfil" element={<Shell content={<Profile />} />} />
        </Route>
      </Route>
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
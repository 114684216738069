import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import createAxiosInstance2 from '../../utils/AxiosConfig2';
import { useParams, useNavigate } from 'react-router-dom';

export const GuiaUnalView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form1, setForm1] = useState({ name: '', id: 0 });
  const [form2, setForm2] = useState({
    title: '',
    generalDescription: '',
    contenidosJson: [],
    linkInterest: '',
    tituloLink: '',
    id: 0,
  });

  useEffect(() => {
    // Fetch the data from the backend
    createAxiosInstance2().get(`/menu-request/${id}`)
      .then(response => {
        const fetchedData = response.data;
        const contenidosRequest = fetchedData.contenidosRequest && fetchedData.contenidosRequest[0] ? fetchedData.contenidosRequest[0] : {};
        setForm1({
          name: fetchedData.name || '',
          id: fetchedData.id,
        });
        setForm2({
          title: contenidosRequest.title || '',
          generalDescription: contenidosRequest.generalDescription || '',
          contenidosJson: contenidosRequest.contenidosJson ? contenidosRequest.contenidosJson.map(content => ({
            ...content,
            imageUrl: content.imageUrl ? `https://sgcertificados.com/api/v1${content.imageUrl}` : '',
          })) : [],
          linkInterest: contenidosRequest.linkInterest || '',
          tituloLink: contenidosRequest.tituloLink || '',
          id: contenidosRequest.id || 0,
        });
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id]);

  const renderForm = (formData, formNumber) => (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {formNumber === 1 && (
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">Título de la sección</Typography>
            <Typography variant="body1">{formData.name}</Typography>
          </Grid>
        )}
        {formNumber === 2 && (
          <>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">Título</Typography>
              <Typography variant="body1">{formData.title}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1" fontWeight="bold">Descripción General</Typography>
              <Typography variant="body1">{formData.generalDescription}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">Título del Enlace</Typography>
              <Typography variant="body1">{formData.tituloLink}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">Enlace</Typography>
              <Typography variant="body1">{formData.linkInterest}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold">Entradas de Contenido</Typography>
            </Grid>
            {formData.contenidosJson.map((content, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1" fontWeight="bold">Título entrada {index + 1}</Typography>
                  <Typography variant="body1">{content.titulo}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1" fontWeight="bold">Contenido entrada {index + 1}</Typography>
                  <Typography variant="body1">{content.contenido}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1" fontWeight="bold">Título del Enlace {index + 1}</Typography>
                  <Typography variant="body1">{content.tituloLink}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="subtitle1" fontWeight="bold">Enlace {index + 1}</Typography>
                  <Typography variant="body1">{content.link}</Typography>
                </Grid>
                {content.imageUrl && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" fontWeight="bold">Imagen entrada {index + 1}</Typography>
                    <Box component="img" src={content.imageUrl} alt={`Imagen ${index + 1}`} sx={{ width: '100%', mt: 1 }} />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h3" gutterBottom>
        Ver entradas de la sección
      </Typography>

      <Box sx={{ bgcolor: '#f0f0f0', p: 2, borderRadius: 1, mt: 2, width: 500 }}>
        <Typography variant="h6" gutterBottom>
          Nota: En esta sección se muestra la información de las subsecciones pero en la app se mostrarán de una manera diferente.
        </Typography>
      </Box>
      
      {renderForm(form1, 1)}
      {renderForm(form2, 2)}
      <Button variant="contained" color="secondary" onClick={() => navigate('/ListaUnal')} sx={{ mt: 2 }}>
        Terminar
      </Button>
    </Box>
  );
};

export default GuiaUnalView;
